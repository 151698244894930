var render = function render(){var _vm=this,_c=_vm._self._c;return _c('content-layout',{attrs:{"loading":_vm.loading,"breadcrumbs-items":_vm.breadcrumbsItems}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-tabs',{staticStyle:{"border-radius":"8px"},attrs:{"color":"info"},model:{value:(_vm.filtersState.tab),callback:function ($$v) {_vm.$set(_vm.filtersState, "tab", $$v)},expression:"filtersState.tab"}},_vm._l((_vm.tabs),function(item){return _c('v-tab',{key:item.text},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1),_c('v-toolbar',{staticClass:"mt-2",attrs:{"flat":"","color":"background"}},[_c('v-spacer'),_c('cz-button',{attrs:{"title":_vm.$t('common.filters'),"icon-src":_vm.hasActiveFilters(_vm.filtersState.filters)
          ? _vm.mdiFilterCheckOutline
          : _vm.mdiFilterOutline,"outlined":!_vm.hasActiveFilters(_vm.filtersState.filters),"color":_vm.hasActiveFilters(_vm.filtersState.filters) ? 'accent darken-1' : 'primary',"loading":_vm.loadingFilters},on:{"click":function($event){_vm.showFiltersBar = !_vm.showFiltersBar}}})],1),_c('cz-data-table',_vm._b({staticClass:"mt-2",attrs:{"height":"calc(100vh - 390px)","options":_vm.filtersState.options,"show-expand":_vm.currentTab.expandable,"single-expand":"","fixed-header":"","item-key":"_id"},on:{"update:options":function($event){return _vm.$set(_vm.filtersState, "options", $event)}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function({ item, expand, isExpanded }){return [(item.childSubmissionsIds && item.childSubmissionsIds.length)?_c('cz-button',{attrs:{"text":"","icon-size":28,"width":28,"height":28,"min-width":28,"ripple":true,"icon-src":isExpanded ? _vm.mdiChevronUp : _vm.mdiChevronDown},on:{"click":function($event){$event.stopPropagation();return _vm.onItemExpand(item, expand, isExpanded)}}}):_vm._e()]}},{key:"expanded-item",fn:function({ item, headers }){return [_c('td',{staticClass:"pa-3 background",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"outlined":"","rounded":"lg"}},[_c('v-card-text',[_c('cz-data-table',_vm._b({staticStyle:{"max-height":"300px"},attrs:{"hide-default-footer":"","columns":_vm.childColumns,"inner-table":"","fixed-header":""},on:{"click:row":_vm.onSubmissionClicked},scopedSlots:_vm._u([{key:"item.documentNumber",fn:function({ item }){return [_c('router-link',{staticClass:"font-weight-semibold text-body-1 button--text",attrs:{"to":{
                    name: 'customer-submission',
                    params: {
                      submissionId: item._id
                    }
                  }}},[_vm._v(" "+_vm._s(item.documentNumber)+" ")])]}},{key:"item.issueDate",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDateToDisplay(item.issueDate)))])]}},{key:"item.status",fn:function({ item }){return [_vm._v(" "+_vm._s(item.statusText)+" ")]}},{key:"item.createdAt",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDateToDisplay(item.createdAt)))])]}},{key:"item.nav",fn:function({ item }){return [_c('cz-button',{attrs:{"icon-size":24,"text":"","icon-src":_vm.mdiChevronLeft,"to":{
                    name: 'customer-submission',
                    params: {
                      submissionId: item._id
                    }
                  },"color":"info"}})]}}],null,true)},'cz-data-table',_vm.childSubmissionsTable,false))],1)],1)],1)]}},{key:"item.documentNumber",fn:function({ item }){return [_c('router-link',{staticClass:"font-weight-semibold text-title button--text",attrs:{"to":{
          name: 'customer-submission',
          params: {
            submissionId: item._id
          }
        }}},[_vm._v(" "+_vm._s(item.documentNumber)+" ")])]}},{key:"item.documentStatus",fn:function({ item }){return [(item.documentStatusText)?_c('cz-chip',{attrs:{"text":item.documentStatusText,"small":"","color":item.documentStatus === _vm.SubmissionDocumentStatus.OPEN
            ? 'buttonSecondary darken-1'
            : 'primary',"dark":""}}):_vm._e()]}},{key:"item.status",fn:function({ item }){return [_vm._v(" "+_vm._s(item.statusText)+" ")]}},{key:"item.issueDate",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDateToDisplay(item.issueDate)))])]}},{key:"item.createdAt",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDateToDisplay(item.createdAt)))])]}},{key:"item.totalAmount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency(item.totalAmount, item.currency))+" ")]}},{key:"item.vatAmount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency(item.vatAmount, item.currency))+" ")]}},{key:"item.netAmount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency(item.netAmount, item.currency))+" ")]}},{key:"item.vendor",fn:function({ item }){return [(item.vendor)?_c('vendor-info-menu',{attrs:{"vendor":item.vendor}}):_vm._e()]}},{key:"item.relatedOrder",fn:function({ item }){return [(item.relatedOrder)?_c('order-info-menu',{attrs:{"order":item.relatedOrder,"show-order-link":""},on:{"show-order":function($event){return _vm.onShowFullOrder(item)}}}):(item.source === 'orderless')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('customer.orderlessSubmissions.orderlessSubmission'))+" ")]):_vm._e()]}},{key:"item.approvalRound",fn:function({ item }){return [_c('cz-button',{attrs:{"title":_vm.$t('customer.orders.viewApprovalRound'),"color":"primary","small":"","icon-src":_vm.mdiCheckAll},on:{"click":function($event){$event.stopPropagation();return _vm.showApprovalRoundForItem(item)}}})]}},{key:"item.approverUserId",fn:function({ item }){return [(item.approverUser)?_c('user-info-menu',{attrs:{"user":item.approverUser}}):_vm._e()]}},{key:"item.nav",fn:function({ item }){return [_c('cz-button',{attrs:{"icon-size":30,"text":"","icon-src":_vm.mdiChevronLeft,"to":{
          name: 'customer-submission',
          params: {
            submissionId: item._id
          }
        },"color":"info"}})]}}])},'cz-data-table',_vm.submissionsTable,false)),(_vm.filtersState.filters)?_c('filters-bar',{attrs:{"filter-fields":_vm.filtersState.filters},on:{"close":function($event){_vm.showFiltersBar = false},"on-filter":_vm.onFilter},model:{value:(_vm.showFiltersBar),callback:function ($$v) {_vm.showFiltersBar=$$v},expression:"showFiltersBar"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }